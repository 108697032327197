import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import { Link, graphql } from "gatsby"
// import { Container } from "reactstrap"
import Swiper from "@components/swiper/swiper"
import PodCastSwiper from "@components/swiper/swiper"
// import SampleForm from "@components/form/form"
import Hero from "@components/hero"
import {
	LazyLoadImage,
	LazyLoadComponent,
} from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

const Trainings = () => (
	<Layout>
		<SEO
			title="Trainings"
			description="The NARM Practitioner Training is designed for psychotherapists, social workers, counselors and mental health professionals who work with trauma. Graduate trainees and interns are welcome to apply."
		/>
		<div class="page-headline">
			<div class="container">
				<div class="section-heading text-center">
					<h5 class="font-weight-bold text-uppercase text-orange flair mb-0">
						NARM Practitioner Training
					</h5>
					<h1>Trainings</h1>
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="container">
				<section id="trainingspage" className="component">
					<div className="container px-5 px-md-3">
						<div className="row">
							<div className="col-md-6 mb-4">
								<div className="card shadow h-100">
									<div
										className="card-img"
										style={{
											backgroundImage: 'url("/assets/img/sfbay.jpg")',
										}}
									>
										<div className="card-link">
											<Link to="/schedule">
												<LazyLoadImage
													effect="blur"
													src="/assets/img/play.svg"
													width={14}
													height={14}
												/>
											</Link>
										</div>
									</div>
									<div className="card-body">
										<h6 className="text-uppercase card-subtitle mb-2 font-weight-bold text-orange">
											NORTH AMERICA
										</h6>
										<h4 className="card-title text-primary">
											NARM North America Trainings
										</h4>
										<div className="card-created">
											{/*<p className="text-muted mb-0">
												<LazyLoadImage
													effect="blur"
													src="/assets/img/calendar.svg"
													width={20}
													height={20}
												/>
												<span className="ml-2">Sept 2019 - Jun 2021</span>
											</p>*/}
										</div>
									</div>
									<Link className="hidden-link" to="/schedule/">
										Read More
									</Link>
								</div>
							</div>
							<div className="col-md-6 mb-4">
								<div className="card shadow h-100">
									<div
										className="card-img"
										style={{
											backgroundImage: 'url("/assets/img/paris.jpg")',
										}}
									>
										<div className="card-link">
											<a href="http://drlaurenceheller.com/" target="_blank" rel="noopener noreferrer">
												<LazyLoadImage
													effect="blur"
													src="/assets/img/play.svg"
													width={14}
													height={14}
												/>
											</a>
										</div>
									</div>
									<div className="card-body">
										<h6 className="text-uppercase card-subtitle mb-2 font-weight-bold text-orange">
											<strong>INTERNATIONAL</strong>
										</h6>
										<h4 className="card-title text-primary">
											The NARM International Trainings
										</h4>
										<div className="card-created">
											{/*<p className="text-muted mb-0">
												<LazyLoadImage
													effect="blur"
													src="/assets/img/calendar.svg"
													width={20}
													height={20}
												/>
												<span className="ml-2">Sept 2019 - Jun 2021</span>
											</p>*/}
										</div>
									</div>
									<a
										className="hidden-link"
										href="https://drlaurenceheller.com/narm-training/"
										target="_blank"
										rel="noreferrer"
									>
										Read More
									</a>
								</div>
							</div>
						</div>
						{/*<p className="d-flex flex-column flex-sm-row justify-content-center mt-4 mb-4">
							<a
								className="btn btn-primary shadow mr-sm-3 mb-3 mb-sm-0 arrow"
								href="#"
							>
								View More
							</a>
							<a className="btn btn-primary inverted shadow arrow" href="#">
								International Trainings
							</a>
						</p>*/}
						<div className="intro mb-4 ">
							<div className="row">
								<div className="col-md-9 mx-auto text-center">
									<h2 className="text-primary">
										<span className="text-orange">Training Format</span>
									</h2>
									<p className="text-muted">
										Trainings are held in four 4.5-day modules (click on
										specific training location below).{" "}
										<strong>
											Also offered: Online and Live NARM Consult Groups, NARM
											Experiential Consult Sessions, NARM Study Groups, access
											to NARM Demonstration Videos and Transcripts, and an
											optional Master’s Level Training
										</strong>{" "}
										with NARM Creator Dr. Laurence Heller.
									</p>
									<hr />
								</div>
							</div>
						</div>
						<div className="intro mb-4 ">
							<div className="row">
								<div className="col-md-9 mx-auto text-center">
									<h2 className="text-primary">
										<span className="text-orange" />
										CEU's
									</h2>
									<p className="text-muted">
										Available for specific training location below.{" "}
										<strong>
											psychologists, psychotherapists, marriage and family
											therapists, social workers and professional counselors
										</strong>{" "}
										[120 CEU Hours for NARM Therapist/Practitioner Certificate].
									</p>
									<Link
										className="btn btn-primary inverted shadow arrow"
										to="/trainings/ceus"
									>
										Click Here for Continuing Education Information
									</Link>
									<hr />
								</div>
							</div>
						</div>
						<div className="intro mb-4 bg-light ">
							<div className="row">
								<div className="col-md-9 mx-auto text-center">
									<h2 className="text-primary">
										<span className="text-orange">Admission Requirements</span>
									</h2>
									<p className="text-muted">
										The NARM Practitioner Training is designed for{" "}
										<strong>
											psychotherapists, social workers, counselors and mental
											health professionals
										</strong>{" "}
										who work with trauma. Graduate trainees and interns are
										welcome to apply. Other health care professionals or
										educators working with Complex Trauma will be considered.
										Applications are approved on a case-by-case basis, and may
										include a personal interview with the NARM coordinator
										and/or faculty member.
									</p>
									<hr />
								</div>
							</div>
						</div>
						<div className="intro mb-4 ">
							<div className="row">
								<div className="col-md-9 mx-auto text-center">
									<h2 className="text-primary">
										<span className="text-orange" />
										NARM Training Certificate
									</h2>
									<p className="text-muted">
										A certificate of completion is issued to all participants
										upon completion of the requirements for the NARM
										Practitioner Training. Additionally, the NARM Training
										Institute provides 2 designations for graduates of the NARM
										Practitioner Training, depending on licensure and training:{" "}
										<strong>NARM Therapists and NARM Practitioners.</strong>{" "}
									</p>
									<Link
										className="btn btn-primary inverted shadow arrow"
										to="/narm-certificate-completion/"
									>
										For more information on the difference between NARM
										Therapists and NARM Practitioners, please click here.
										Disclaimer
									</Link>
									<hr />
								</div>
							</div>
						</div>
						<div className="intro mb-4 bg-light ">
							<div className="row">
								<div className="col-md-9 mx-auto text-center">
									<h2 className="text-primary">
										<span className="text-orange">Disclaimer</span>
									</h2>
									<p className="text-muted">
										The NARM Training Institute is neither a regulatory nor
										licensing organization and therefore not sanctioned to{" "}
										<strong>
											certify, license, or otherwise bestow the legal
											authorization to practice as a mental health professional.
										</strong>{" "}
										While the theoretical approach taught in NARM offers a
										useful framework which can support professionals from
										various clinical disciplines, all participants in the NARM
										Practitioner Training should clarify to what extent using
										the NARM approach in their practices is condoned by their
										licensing body. Acceptance into the NARM Practitioner
										Training does not ensure that all NARM methods will be
										appropriate for inclusion in your professional practice.
										Training participants are responsible for operating within
										their professional scope of practice and for abiding by
										state and federal laws.
									</p>
									<hr />
								</div>
							</div>
						</div>
						<style
							dangerouslySetInnerHTML={{
								__html:
									".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }",
							}}
						/>
						<div className="embed-container">
							<iframe
								src="https://www.youtube.com/embed//Bm4xEqkUhDc"
								frameBorder={0}
								allowFullScreen
							/>
						</div>
					</div>
				</section>
			</div>
		</div>
	</Layout>
)

export default Trainings